/* StepNow.scss */

.step-now-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.stepnow {
  width: 100%;
}

.step-now-container {
  position: relative;
}

.button-project-back {
  position: absolute;
  top: 20px; /* Adjust as needed */
  left: 20px; /* Adjust as needed */
  z-index: 10; /* Ensure button is above other content */
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000; /* Set button text color */
}

.icon-back-to-home {
  width: 24px; /* Set the width of the back icon */
  height: auto; /* Maintain aspect ratio */
  margin-right: 5px; /* Adjust spacing between icon and text */
}

.text-button {
  font-size: 16px; /* Adjust button text size */
}
