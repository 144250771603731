.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project {
  margin: 10px;
  position: relative;
  width: 40vw; /* Match the width of the image */
}

.project-image {
  width: 100%; /* Ensure the image takes up the full width of the project div */
  height: auto;
  transition: filter 0.5s ease;
}

.hover-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.project:hover .hover-text {
  opacity: 1;
  color: black;
  font-weight: 600;
}

.project:hover .project-image {
  opacity: 0.3;
}

.tags {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.tags button {
  margin: 0 5px;
  padding: 8px 14px;
  background-color: #ececec; /* Adjust as needed */
  border: none;
  color: #8d8d8d;
  font-weight: 650;
  font-size: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 30px;
  cursor: default;
}
