@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/spoilers-script");

.App {
  text-align: center;
  transition: all 0.5s;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.page-buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-button {
  background-color: white;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  margin: 5px;
}

.App-header {
  background-color: white;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.heading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  font-size: 64px;
  color: #000000; /* Black color */
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  margin-left: 5vw;
}

.gradient-text {
  background: linear-gradient(to right, #ff218c, #00a6ff);
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.line-break {
  display: block;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page {
  min-height: 100vh;
  max-width: 100vw;
  text-align: center;
  transition: all 0.5s;
}

.homepage {
  min-height: 100vh;
  overflow-y: hidden;
  text-align: center;
  transition: all 0.5s;
  background-color: transparent;
}

.homepage.fade {
  opacity: 0;
}

.background-image {
  position: relative;
}

.left-image {
  position: absolute;
  margin-left: 18%;
  z-index: 1;
}

.right-image {
  position: relative;
}

.card-image {
  position: absolute;
  z-index: 2;
}

.page-title {
  font-family: "Spoilers Script", sans-serif;
  color: black;
}

/* #section-about {
  display: flex;
  align-items: center;
} */

.about-content {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust the gap value as needed */
}

.about-para {
  flex: 1; /* Allow the paragraph to take up remaining space */
  color: black;
}

.App-footer {
  margin: auto 0;
  padding: 30px 20px; /* Adjust the padding as needed */
  margin-bottom: 0;
}

.projects {
  margin-bottom: 6rem;
}

/* Scroll button styles */
.container_mouse {
  position: fixed;
  right: 20px; /* Adjust the distance from the right side */
  bottom: 20px; /* Adjust the distance from the bottom */
  z-index: 9999; /* Ensure it's above other content */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mouse-btn {
  margin: 5px auto;
  width: 20px;
  height: 42px;
  border: 2px solid rgb(123, 124, 124);
  border-radius: 20px;
  display: flex;
}

.mouse-scroll {
  display: block;
  width: 10px;
  height: 10px;
  background: linear-gradient(
    170deg,
    rgba(122, 122, 124, 0.918),
    rgb(123, 124, 124)
  );
  border-radius: 50%;
  margin: auto;
  animation: scrolling13 1s linear infinite;
}

@keyframes scrolling13 {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(20px);
  }
}

.container_mouse span {
  font-size: 12px; /* Adjust the size as needed */
  font-weight: 500;
  color: rgb(123, 124, 124);
}
