.navigation {
  display: flex;
  justify-content: flex-end; // Align items to the start
  list-style: none;
  position: relative;
  margin-top: 1vw;
  //padding-right: 40px;
  width: 100vw;
  opacity: 0;
  margin-bottom: 10vh;
}

.navbar {
  display: flex;
  justify-content: space-around; /* Space elements evenly */
  align-items: center; /* Align items vertically */
  list-style: none;
  position: relative;
  margin-top: 1vw;
  padding: 0 40px; /* Adjust padding */
  width: 100vw;
  opacity: 1;
  margin-bottom: 12vh;
}

.logo {
  margin-right: auto; /* Push logo to the far left */
  margin-left: 3rem;
  margin-top: 1rem;
}

.navigationItem {
  display: inline;
  //padding: 1px;
  position: relative;
  z-index: 2;
  margin-right: 1rem;
  font-size: 12px; // Set font size to 12px
  font-weight: 600; // Set font weight to 600 (semi bold)
  a {
    text-decoration: none;
    color: rgb(0, 0, 0);
    padding: 5px;
  }
}

nav ul {
  margin-right: 2rem;
}

nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all 0.2s;
}

nav ul li a:hover {
  color: rgb(136, 183, 255);
  //font-weight: bolder;
  text-decoration: underline 2px solid #00a6ff;
}

.opaque {
  opacity: 1;
}

a.active:hover {
  text-decoration: underline 2.5px solid #00a6ff;
  text-underline-offset: 5px;
}

.active {
  text-decoration: underline 1px solid white;
}
